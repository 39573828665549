@require "../../common/css/mixins";
@require "../../common/css/media-queries";

$color-blue = $nrk-color-core-blue-500;

$color-bottommenu-background = $nrk-color-core-blue-100;
$color-bottommenu-text = $nrk-color-core-blue-950;

$color-footer-background = $nrk-color-core-blue-950;
$color-footer-text = $nrk-color-core-blue-200;

// --- DARKMODE ---
html.darkmode {
  .nrk-bottommenu {
    $color-bottommenu-background = $nrk-color-core-blue-900;
    $color-bottommenu-text = $darkmode-text;

    background-color: $color-bottommenu-background;
    color: $color-bottommenu-text;

    #icon-close,
    #logo-presseforbundet,
    #logo-presseforbundet-with-text {
      color: $darkmode-text;
    }


    .nrk-bottommenu .grid .nrk-bottommenu-info .nrk-bottommenu-info-presseforbundet #pfu-popup

    .grid {
      .nrk-bottommenu-info {
        .nrk-bottommenu-info-tips-button {
          background-color: $nrk-color-core-blue-900;
          color: $darkmode-text;
          border: 1px solid $nrk-color-core-blue-200;
          svg {
            color: $color-white;
          }
        }

        .nrk-bottommenu-info-presseforbundet {
          #pfu-popup {
            background-color: $nrk-color-core-blue-800;
            color: $nrk-color-core-blue-50;
            &:before {
              background-color: $nrk-color-core-blue-50;
            }
            &:after {
              border-color: $nrk-color-core-blue-800
            }
            #pfu-popup-close {
              background-color: $nrk-color-core-blue-800;
              color: $darkmode-text;
              border: 1px solid $nrk-color-core-blue-200;
              svg {
                color: $nrk-color-core-blue-50;
              }
            }
            hr {
              background-color: $nrk-color-core-blue-50;
            }
          }
        }
      }

      .nrk-bottommenu-to-top {
        .to-top {
          background-color: $nrk-color-core-blue-900;
          color: $darkmode-text;
          border: 1px solid $nrk-color-core-blue-200;
          &:hover {
            border-color: $nrk-color-core-blue-500;
          }
          svg {
            color: $color-white;
          }
        }
      }
      .nrk-bottommenu-colophons {
        .colophon {
          label {
            background-color: $nrk-color-core-blue-800;
            color: $nrk-color-core-blue-50;
            svg {
              color: $nrk-color-core-blue-400;
            }
          }
          .colophon-list-item {
            @media $mq-medium {
               background-color: transparent;
            }
            background-color: rgba($nrk-color-core-blue-800, 0.5);
            a {
              border-color: $nrk-color-core-blue-900;
              &:hover {
                @media $mq-large {
                  color: $nrk-color-core-blue-200;
                }
              }
            }
          }
        }
      }
    }
  }
}
// --- DARKMODE END ---

.nrk-bottommenu {
  * { box-sizing: border-box }


  #icon-close,
  #logo-presseforbundet {
    color: #26292A
  }

  #logo-presseforbundet-with-text {
    color: #0B0B0A
  }

  display: block!important;
  font-nrk-sans();
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: $color-bottommenu-text;
  background-color: $color-bottommenu-background;
  padding: 15px 10px;

  @media $mq-medium {
    padding: 35px 15px;

    ul:last-child {
      margin-left: 20px;
    }
  }

  @media $mq-large {
  h3 {
  font-weight: 700;
  }
  }

  @media print {
    display: none!important;
  }

  a {
    font-size: inherit;
    color: inherit;
    text-decoration: none;
    border: 0;
  }

  p {
    color: inherit;
    text-align: inherit;
    text-decoration: inherit;
    text-transform: inherit;
  }

  .grid {
    margin: 0 auto 0 auto;
    max-width: 1150px;
    float: none;
    clear: both;
    background-color: inherit;

    @media $mq-medium {
      position: relative;
    }

    .nrk-bottommenu-info {
      text-align: center;
      width: 100%;

      @media $mq-medium {
        float: left;
        width: 44%;

        @media $mq-large {
          width: 30%;
          padding: 0 5px;
        }
      }

      input {
        display: none;
      }

      h3 {
        margin: 0;
        text-align: center;
      }

      ul {
        padding-left: 0;
        margin: 0 0 15px 0;
        list-style-type: none;
        list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");

        li {
          margin-bottom: 4px;
          text-align: center;
          font-size: 14px;
        }
      }

      .nrk-bottommenu-logo {
        display: inline-block;
        min-height: 19px;
        min-width: 45px;
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 14px;
        margin-left: 1px;

        svg {
          height: 16px;
          width: 43px;

          + span {
            margin-left:5px;
            font-size: 20px;
          }
        }
      }

      .nrk-bottommenu-info-circle-logo {
        display: inline-block;
        width: 70px;
        height: 70px;
        margin: 5px 0 18px 0;
      }

      .nrk-bottommenu-info-presseforbundet {
        position: relative;

        #pfu-toggle {
          display: block;
          position: absolute;
          height: 34px;
          width: 34px;
          left: 0;
          right: 0;
          margin: 0 auto;
          appearance: none;
          cursor: pointer;
          border: none;
          background-color: transparent;
          z-index: -1;

          &[aria-expanded="true"] + #pfu-popup {
            display: block;
          }

          &:focus ~ label {
            outline: 2px solid $color-blue;
          }
        }

        input {
          display: inline-block;
        }

        label {
          display: block;
          height: 34px;
          width: 34px;
          margin: 15px auto 0 auto;

          svg {
            cursor: pointer;
            width: 34px;
            height: 34px;
          }

        }

        #pfu-popup {
          display: none;
          position: absolute;
          top: -517px;
          height: 502px;
          width: 285px;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          padding: 15px 20px;
          padding-top: 35px;
          box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
          background-color: white;
          z-index: 92;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 10px;
            width: 100%;
            background-color: $nrk-color-gray-950;
          }

          &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            bottom: -1em;
            left: 131px;

            border: .5em solid black;
            border-color: transparent transparent #fff #fff;

            transform-origin: 0 0;
            transform: rotate(-45deg);
            box-shadow: -2px 2px 4px 0 rgba(0,0,0,0.1);
          }

          h4 {
            font-size: 16px;
            font-weight: 600;
            line-height: 25px;
            margin: 10px 0;
          }

          p {
            font-size: 13px;
            line-height: 21px;
            margin: 10px 0;
          }

          svg {
            height: 63px;
            width: 185px;
          }

          hr {
            width: 100px;
            height: 2px;
            background-color: #000;
            border: none;
            margin-top: 25px;
            margin-bottom: 25px;

            &:first-of-type {
              margin-top: 25px;
              margin-bottom: 25px;
            }
          }


          #pfu-popup-close {
            cursor: pointer;
            height: 40px;
            width: 40px;
            background-color: #fff;
            border: 1px solid $nrk-color-gray-500;
            border-radius: 50%;
            padding: 9px;
            margin-bottom: 25px;

            &:hover {
              border-color: $color-blue;
            }

            svg {
              height: 100%;
              width: 100%;
            }
          }
        }
      }


      .nrk-bottommenu-info-tips {
        margin-top: 30px;
        margin-bottom: 30px;

        @media $mq-medium {
          margin-bottom: 50px;
        }
      }

      .nrk-bottommenu-info-tips-button {
        display: block;
        position: relative;
        color: $color-bottommenu-text;
        font-weight: 700;
        background-color: $nrk-color-core-blue-200;
        border-radius: 40px;
        padding: 12px 25px;
        margin: 0 auto 10px;
        max-width: 16rem;
          text-align: left;

        &:hover {
          border-color: $color-blue !important;
        }

        .nrk-bottommenu-info-tips-text {
          padding-right: 33px;
        }
        svg {
          position: absolute;
          right: 14px;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 12px;
          width: 32px;
          color: $nrk-color-core-blue-600;
        }
      }
    }

    .nrk-bottommenu-colophons {
      width: 100%;

      @media $mq-medium {
        width: 55%;
        float: left;

        @media $mq-large {
          float: right;
          width: 68%;
        }
      }

      .colophon {

        @media $mq-large {
          float: left;
          padding:0 5px;

          &.first {
            width: 30%;
          }
          &.second {
            width: 40%;
            padding:0 10px;
          }
          &.third {
            width: 30%;
            padding-right:0;
          }
        }

        input {
          display: inline-block;
          position: absolute;
          height: 42px;
          width: 100%;
          left: 0;
          appearance: none;
          border: none;
          background-color: transparent;
          cursor: pointer;
          z-index: -1;
          margin: 0;

          @media $mq-large {
            display: none;
          }

          &:focus + label {
            outline: 2px solid $color-blue;
          }

          &[type=checkbox]:checked + label {
            margin-bottom: 0;
            border-radius:6px 6px 0 0

            svg {
              transform: rotate(180deg);
            }

            + ul:nth-child(n) {
              display: block;
            }
          }
        }

        label, h3 {
          font-size: 14px;
          line-height: 24px;
          margin: 0 0 10px;
          background-color: $color-white;
          border-radius:6px;
          padding-left: 8px;
          padding-right: 8px;

          @media $mq-medium {
            font-weight: normal;
          }
        }

        label {
          display: block;
          position: relative;
          padding-left: 20px;
          padding-right: 30px;
          line-height: 40px;
          cursor: pointer;
          user-select: none;

          @media $mq-large {
            display: none;
          }

          &:hover {
            border-color: $color-blue;
          }

          svg {
            position: absolute;
            right: 15px;
            top: 17px;
            color: $nrk-color-core-blue-600;
            height: 7px;
            width: 12px;
          }
        }

        h3 {
          display: none;

          @media $mq-large {
            display: block;
            margin: 0;
            margin-right: 5px;
            background: none;
            padding: 0;
            border: none;
            text-transform: uppercase;
            letter-spacing: 0.3px;
            line-height: 17px;
            font-weight: 700;
          }
        }

        .colophon-list {
          margin: 0 0 10px 0;
          display: none;
          border-top: none;
          padding-left: 0;
          list-style-type: none;

          li:last-child {
            border-radius:0 0 6px 6px;
          }

          @media $mq-large {
            display: block;
            margin-bottom: 0;
            border: none;
            padding-top: 5px;
            padding-bottom: 20px;

            list-style: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 7 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1l4 4-4 4' stroke='%231767ce' stroke-width='2' fill='none'/%3E%3C/svg%3E") inside;
          }

          .colophon-list-item {
            font-size: 14px;
            line-height: 41px;
            background: $nrk-color-core-blue-50;


            @media $mq-large {
              line-height: 31px;
              background-color: $nrk-color-core-blue-100;
            }



            a {
              display: inline-block;
              border-top: 1px solid $nrk-color-core-blue-100;
              padding-left: 20px;
              width: 100%;

              @media $mq-large {
                width: auto;
                border: none;
                padding-left: 0;
              }

              &:hover {
                border-left: 3px solid $nrk-color-core-blue-500;
                padding-left: 17px;

                @media $mq-large {
                  border: none;
                  color: #0059b3;
                  padding-left: 0;
                }
              }
              &:active, &.active {
                border-left: 3px solid $color-blue;

                @media $mq-large {
                  border: none;
                }
              }
            }
          }
        }
      }
    }

    .nrk-bottommenu-to-top {
      display: block;
      position: relative
      text-align: center;
      float: left;
      width: 100%;
      margin-top: 1em;
      margin-bottom: 1em;

      @media $mq-medium {

        @media (max-width: 899px) {
          margin-top: 2em;
        }
      }


      .to-top {
        display: inline-block;
        position: relative;
        width: 50%;
        max-width: 250px;
        padding: 12px 20px;
        border-radius: 77px;
        font-size: 14px;
        background-color: $nrk-color-core-blue-200;
        z-index: 1;

        &:hover {
          border-color: $color-blue;
        }

        svg {
          height: 10px;
          width: 12px;
          color: $nrk-color-core-blue-600;
          margin-right: 5px;
        }
      }
    }
  }

  .cf:before, .cf:after {
    content: ' ';
    display: block;
    padding: 0px !important;
    margin: 0px !important;
    height: 0px !important;
    visibility: hidden;
  }

  .cf:after {
    clear:both;
  }
}

/* Hide footer when html.no-header is present
--------------------------------------------------------------- */
html.no-footer .nrk-bottommenu-footer {
  display: none;
}

.nrk-bottommenu-footer {
  font-nrk-sans();
  position: relative;
  text-align: center;
  padding: 25px 5px;
  background: $color-footer-background;
  color: $color-footer-text;
  min-width: 0 !important;


  @media $mq-medium {
    padding: 37px 15px 25px;
  }

  .grid {
    max-width: none !important;
  }

  a {
    font-size: 14px !important;
    color: $color-footer-text;
    display: inline-block;
    text-decoration: none;

    &.nrk-bottommenu-footer-logo {
      background: transparent url("../../common/img/nrk-logo-white-72x26.png") no-repeat;
      display: block;
      width: 74px;
      height: 26px;
      margin: 0 auto 14px auto;

      @media $mq-retina {
        background-size: 74px auto;
        background-image: url("../../common/img/nrk-logo-white-72x26-retina.png");
      }
    }

    &.nrk-bottommenu-footer-copyright {
      font-weight: 600;
      display: block;
      margin: 1.25rem auto 1rem auto;
    }

    &.nrk-bottommenu-footer-editors {
      span {
        display: block;
        margin: 5px 0;

        @media $mq-medium {
          display: inline-block;
          margin: 5px;
        }
      }
    }
  }
}

// Hide footer when html .no-footer is present
html .no-footer{
  display: none!important;
}

/* Hide text content, but still show screen readers */
.sr-only {
  display: block;
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  border: 0;
  padding: 0;
  margin: 0 -1px -1px 0;
}

/* unispring tracking-script bug, see PUB-6258, PUB-6995 */
img[src*="tns-cs.net"] { display:block; position: absolute; top:0; }
