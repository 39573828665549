/* mixin to generate default skin based on color and relative images */

/* Variables */
@import "../../../../../../node_modules/@nrk/core-design-tokens/dist/color/nrk-color-tokens.styl"


// Color variables not from the NRK-color palette
$color-klima = #3F7F80;
$color-abb = #CC0000;
$color-yr = #09C;
$color-mgp = #01B3DC;
$color-osl2016 = #c30158;
$color-white = #ffffff
$color-sport = #e51c30

$default-skin-color = $nrk-color-core-blue-600
$text-color = $nrk-color-core-blue-950
$text-color-anchor = $nrk-color-core-blue-600
$color-dice = $nrk-color-red-500;

// Darkmode variables

// This color is not part of the NRK color palette.
// There was no suitable color in the palette to use as body-background in darkmode, so we defined
// a custom color for this purpose.
$darkmode-body-background = #030F1E;
$darkmode-background = $nrk-color-core-blue-950;
$darkmode-text = $nrk-color-core-blue-100;
$darkmode-text-byline = $nrk-color-core-blue-200;
$darkmode-link = $nrk-color-core-blue-300;
$darkmode-border = $nrk-color-core-blue-800;
$darkmode-border-light = #323f4f // derived from: rgba($nrk-color-core-blue-100, .22);
$darkmode-red-emphasis = $nrk-color-red-700;
$darkmode-button-hover = @block {
  outline: 0;
  border-color: transparent;
  -webkit-box-shadow: 0 0 2px 1px $nrk-color-core-blue-600;
  box-shadow: 0 0 2px 1px $nrk-color-core-blue-600;
}
$darkmode-color-overlay-filter =
	filter: brightness(0) saturate(100%) invert(90%) sepia(16%) saturate(7478%) hue-rotate(181deg) brightness(92%) contrast(101%);

generate-skin(
  $skinColor50 = $nrk-color-core-blue-50,
  $skinColor100 = $nrk-color-core-blue-100,
  $skinColor200 = $nrk-color-core-blue-200,
  $skinColor300 = $nrk-color-core-blue-300,
  $skinColor400 = $nrk-color-core-blue-400,
  $skinColor500 = $nrk-color-core-blue-500,
  $skinColor600 = $nrk-color-core-blue-600,
  $skinColor700 = $nrk-color-core-blue-700,
  $skinColor800 = $nrk-color-core-blue-800,
  $skinColor900 = $nrk-color-core-blue-900,
  $skinColor950 = $nrk-color-core-blue-950) {

  $colors = {
    '50': $skinColor50,
    '100': $skinColor100,
    '200': $skinColor200,
    '300': $skinColor300,
    '400': $skinColor400,
    '500': $skinColor500,
    '600': $skinColor600,
    '700': $skinColor700,
    '800': $skinColor800,
    '900': $skinColor900,
    '950': $skinColor950,
    "white": $color-white
  }
  $alphaValues = 60 100

    for  $colorValue, $colorName in $colors {
    .skin-background-{$colorValue}, html.darkmode .darkmode-skin-background-{$colorValue} {
      background-color: $colorName;
    }


    .skin-text-{$colorValue}, html.darkmode .darkmode-skin-text-{$colorValue} {
      color: $colorName;
      }

    .skin-anchor-child-{$colorValue} a, html.darkmode .darkmode-skin-anchor-child-{$colorValue} a {
      color: $colorName;
      border-bottom: 1px solid $colorName;
      padding-bottom: 2px;
      }

      .skin-anchor-child-{$colorValue}--hover a:hover, html.darkmode .darkmode-skin-anchor-child-{$colorValue}--hover a:hover {
      color: $colorName;
      border-bottom: 1px solid $colorName;
      padding-bottom: 2px;
      }


    .skin-border-{$colorValue}, .skin-border-{$colorValue}--hover:hover, html.darkmode .darkmode-skin-border-{$colorValue}, html.darkmode .darkmode-skin-border-{$colorValue}-hover:hover {
      border-color: $colorName;
      }

    for $alphaValue in $alphaValues {
      .skin-{$colorValue}-fade-background-{$alphaValue}, html.darkmode .darkmode-skin-{$colorValue}-fade-background-{$alphaValue} {
        background: linear-gradient(rgba($colorName, 0), rgba($colorName, $alphaValue/100));
        }
      }
  }

}

generate-legacy-skin(skinColor = $default-skin-color, skinImages = true) {

	// add color to skin-text and skin-text links
	.skin-text,
	a.skin-text,
	a.skin-text:link,
	a.skin-text:visited {
		color: skinColor;
	}

	// add skin color to element on hover
	a.skin-text-hover:hover {
		color: $nrk-color-core-blue-950;
		opacity: 0.7;
	}

	// add border width to include border color
	.skin-border,
	.skin-border-hover:hover {
		border-color: skinColor;
	}

	// adds background color
	.skin-background {
		background-color: skinColor;
	}

	// adds color to selected push button
	.push-button.selected {
		background-color: skinColor;
	}

	// only add skin sprites if flag is set
	if(skinImages) {
		// adds custom plug-icon sprite
		.skin-plug-icon {
			background-image: url(img/plug-sprite.png);
		}

		// adds custom stream + compilation + bulletin sprite
		.bulletin-attachment.plug > a:after,
		.compilation.plug .plug-teaser:after,
		.compilation-aside-wrapper:after {
			background-image: url(img/stream-sprite.png);
		}
	}
}

FONT-FAMILY-NRK-SANS = 'NRK Sans Variable', 'Helvetica Neue', Arial, sans-serif;

fontSetup(fontFamily = FONT-FAMILY-NRK-SANS, fontSize = 18px, lineHeight = 24px, fontStyle = null, fontWeight = null, letterSpacing = null) {
	font-family: fontFamily;
	if(fontStyle != null) {
		font-style: fontStyle;
	}
	if(fontWeight != null) {
		font-weight: fontWeight;
	}
	fontSize(fontSize, lineHeight, letterSpacing);
}

fontSize(fontSize = 18px, lineHeight = 24px, letterSpacing = null) {
	font-size: unit(fontSize/16px, 'em');
	font-size: unit(fontSize/16px, 'rem');
	line-height: unit(lineHeight/fontSize,'');
	if(letterSpacing != null) {
		letter-spacing: letterSpacing;
	}
}

font-nrk-sans(fontSize = 18px, lineHeight = 24px, letterSpacing = null) {
	fontSetup(FONT-FAMILY-NRK-SANS, fontSize, lineHeight, normal, normal, letterSpacing)
}
